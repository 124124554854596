import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import {
  checkAuthentication,
  checkMachineViewPermission,
  checkNoPermission,
  checkUsersViewPermission,
  redirectFromRoot,
} from "@/router/NavigationGuards";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: redirectFromRoot,
  },
  {
    path: "/login",
    name: "Login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "/machines",
    name: "MachineList",
    component: () =>
      import(/* webpackChunkName: "machines" */ "../views/MachineList.vue"),
    beforeEnter: checkMachineViewPermission,
  },
  {
    path: "/machines/:machineID",
    name: "MachineDetails",
    component: () =>
      import(
        /* webpackChunkName: "machines/:machineID" */ "../views/MachineDetails.vue"
      ),
    beforeEnter: checkMachineViewPermission,
  },
  {
    path: "/users",
    name: "UserList",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/UserList.vue"),
    beforeEnter: checkUsersViewPermission,
  },
  {
    path: "/users/:userID",
    name: "UserDetails",
    component: () =>
      import(
        /* webpackChunkName: "users/:userID" */ "../views/UserDetails.vue"
      ),
    beforeEnter: checkUsersViewPermission,
  },
  {
    path: "/blank",
    name: "BlankPage",
    component: () =>
      import(/* webpackChunkName: "blank" */ "../views/BlankPage.vue"),
    beforeEnter: checkNoPermission,
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(checkAuthentication);

export default router;
