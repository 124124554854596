<template>
  <v-dialog
    class="d-flex justify-center"
    v-model="isOpen"
    max-width="290"
    @click:outside="close"
    @keydown="close"
  >
    <v-card>
      <v-card-title class="text-h5"> Il token è scaduto. </v-card-title>
      <v-card-text> Procedere nuovamente all'autenticazione. </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="success" text @click="close"> Ok </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  data: () => ({
    isOpen: false,
  }),

  methods: {
    activateDialog() {
      this.isOpen = true;
    },
    close: function () {
      this.isOpen = false;
      this.$store.dispatch("logout").then(() => {
        this.$router.replace("/login");
      });
    },
  },
});
</script>

<style scoped></style>
