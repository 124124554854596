export default {
  normalizeDate: function (date: Date | null): string {
    if (!date) {
      return "Mai";
    }
    const calendarDate = date.toLocaleDateString();
    const time = date.toLocaleTimeString();
    return `${calendarDate} ${time}`;
  },
};
