






























import Vue from "vue";
import Toolbar from "@/globalComponents/NavBar.vue";
import Footer from "@/globalComponents/Footer.vue";
import { getJwtRemainingTime, getParsedJwt } from "@/utils/JwtUtils";
import TokenExpiredDialog from "@/globalComponents/TokenExpiredDialog.vue";
import { mapGetters } from "vuex";
import axios from "axios";

export default Vue.extend({
  name: "App",
  components: { TokenExpiredDialog, Footer, Toolbar },
  data: () => ({
    tokenTimeout: null,
    snackbarErrorMessage: "",
    snackbarSuccessMessage: "",
    isSnackbarErrorMessageOpen: false,
    isSnackbarSuccessMessageOpen: false,
  }),

  created() {
    this.setTokenTimeout();
  },

  computed: {
    ...mapGetters(["isLoggedIn", "getSnackbarError", "getSnackbarSuccess"]),
  },

  watch: {
    getSnackbarError: function (value: string) {
      if (value) {
        this.snackbarErrorMessage = value;
        this.isSnackbarErrorMessageOpen = true;
      } else {
        this.snackbarErrorMessage = "";
        this.isSnackbarErrorMessageOpen = false;
      }
    },
    getSnackbarSuccess: function (value: string) {
      if (value) {
        this.snackbarSuccessMessage = value;
        this.isSnackbarSuccessMessageOpen = true;
      } else {
        this.snackbarSuccessMessage = "";
        this.isSnackbarSuccessMessageOpen = false;
      }
    },
    isLoggedIn: function (value: boolean) {
      if (value) {
        this.setTokenTimeout();
      } else {
        // @ts-expect-error wrong type
        clearTimeout(this.tokenTimeout);
      }
    },
  },

  methods: {
    setTokenTimeout() {
      const storageTokenKey = process.env.VUE_APP_TOKEN_KEY as string;
      const token = window.localStorage.getItem(storageTokenKey);
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
        const parsedJwt = getParsedJwt(token);
        // @ts-expect-error wrong type
        this.tokenTimeout = setTimeout(() => {
          // @ts-expect-error wrong type
          this.$refs.dialogRef.activateDialog();
        }, getJwtRemainingTime(parsedJwt));
      } else {
        delete axios.defaults.headers.common["Authorization"];
      }
    },
    closeSnackbarError() {
      this.$store.commit("mutation_close_snackbar_error");
    },
    closeSnackbarSuccess() {
      this.$store.commit("mutation_close_snackbar_success");
    },
  },
});
