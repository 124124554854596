const common = {
  branded: "#00A82D",
  primary: "#007d99",
};

export default {
  light: {
    ...common,
  },
  dark: {
    ...common,
  },
};
