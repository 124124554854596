const common = {
  branded: "#0EE1F4",
  primary: "#007d99",
};

export default {
  light: {
    ...common,
  },
  dark: {
    ...common,
  },
};
