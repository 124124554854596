import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "@fortawesome/fontawesome-free/css/all.css";
import ecozanzTheme from "@/themes/ecozanz";
import saputaTheme from "@/themes/saputa";

function loadTheme() {
  return window.location.origin.indexOf("sgd-group") >= 0
    ? saputaTheme
    : ecozanzTheme;
}

const options = {
  iconfont: "fa",
  theme: {
    themes: {
      ...loadTheme(),
    },
  },
};

Vue.use(Vuetify);

export default new Vuetify(options);
