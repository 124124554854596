type Jwt = {
  readonly exp: number;
};

export const getParsedJwt = (token: string): Jwt => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const getJwtRemainingTime = (parsedJwt: Jwt): number =>
  Math.max(parsedJwt.exp * 1000 - Date.now() - 5000, 0);
