























































import logoSrc from "@/assets/logoSrc";
import Vue from "vue";
import {
  hasUserViewPermission,
  hasMachineViewPermission,
} from "@/utils/UserPermissions";

export default Vue.extend({
  name: "NavBar",
  data: () => ({
    logoSrc,
  }),
  methods: {
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.replace("/login");
      });
    },
    isUserButtonVisible() {
      return hasUserViewPermission(this.$store.getters.userRoles);
    },
    isMachineButtonVisible() {
      return hasMachineViewPermission(this.$store.getters.userRoles);
    },
  },
});
