import Vue from "vue";
import Vuex from "vuex";
import { login, logout } from "@/api/AuthenticationAPI";
import { ILogin_R, IRole } from "@iot-uta-devices/iot-uta-devices-interfaces";

const storageTokenKey = process.env.VUE_APP_TOKEN_KEY as string;
const storageUserKey = process.env.VUE_APP_USER_KEY as string;

Vue.use(Vuex);

// ToDo: per ora le informazioni utente sono modificabili a mano nel localstorage e l'applicazione lato client non è a conoscenza dell'effettiva validità dei dati utente.
export default new Vuex.Store({
  state: {
    snackBarError: "",
    snackBarSuccess: "",
    status: "",
    token: window.localStorage.getItem(storageTokenKey) || "",
    user: (JSON.parse(
      window.localStorage.getItem(storageUserKey) || "null"
    ) || {
      username: "",
      description: "",
      roles: {} as IRole,
    }) as ILogin_R["user"],
  },
  mutations: {
    mutation_auth_request(state) {
      state.status = "loading";
    },
    mutation_auth_success(
      state,
      arg: { token: string; user: ILogin_R["user"] }
    ) {
      state.status = "success";
      state.token = arg.token;
      state.user = {
        username: arg.user.username,
        description: arg.user.description as string,
        roles: arg.user.roles,
      };
    },
    mutation_auth_error(state) {
      state.status = "error";
    },
    mutation_logout(state) {
      state.status = "";
      state.token = "";
      state.user = { username: "", description: "", roles: {} as IRole };
    },
    mutation_open_snackbar_error(state, message: string) {
      state.snackBarError = message;
    },
    mutation_close_snackbar_error(state) {
      state.snackBarError = "";
    },
    mutation_open_snackbar_success(state, message: string) {
      state.snackBarSuccess = message;
    },
    mutation_close_snackbar_success(state) {
      state.snackBarSuccess = "";
    },
  },
  actions: {
    login: login,
    logout: logout,
  },
  modules: {},
  getters: {
    isLoggedIn: (state): boolean => !!state.token,
    authStatus: (state): string => state.status,
    userName: (state): string => state.user.username,
    userRoles: (state): IRole => state.user.roles,
    getSnackbarError: (state): string => state.snackBarError,
    getSnackbarSuccess: (state): string => state.snackBarSuccess,
  },
});
