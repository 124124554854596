import axios, { AxiosError, AxiosResponse } from "axios";
import { ILogin, ILogin_R } from "@iot-uta-devices/iot-uta-devices-interfaces";
import { Commit } from "vuex";

type ILogin_Data = { readonly data: ILogin_R };

const storageTokenKey = process.env.VUE_APP_TOKEN_KEY as string;
const storageUserKey = process.env.VUE_APP_USER_KEY as string;

export const login = (
  { commit }: { commit: Commit },
  payload: ILogin
): Promise<void> =>
  new Promise((resolve, reject) => {
    commit("mutation_auth_request");
    const baseUrl = process.env.VUE_APP_SERVER_BASE_URL;
    axios
      .post(`${baseUrl}/login`, { data: payload })
      .then((response: AxiosResponse<ILogin_Data>) => {
        const token = response.data.data.jwt;
        const user = response.data.data.user;

        window.localStorage.setItem(storageTokenKey, token);
        window.localStorage.setItem(storageUserKey, JSON.stringify(user));
        axios.defaults.headers.common["Authorization"] = token;

        commit("mutation_auth_success", { token, user });

        resolve();
      })
      .catch((error: AxiosError) => {
        let formattedError;
        if (error.response?.data?.message) {
          formattedError = new Error(
            /* error.response.data.message */ "Credenziali errate."
          );
        } else {
          formattedError = new Error("Errore sconosciuto!");
        }
        window.localStorage.removeItem(storageTokenKey);
        window.localStorage.removeItem(storageUserKey);
        commit("mutation_auth_error");
        reject(formattedError);
      });
  });

export const logout = ({ commit }: { commit: Commit }): Promise<void> =>
  new Promise((resolve) => {
    commit("mutation_logout");
    window.localStorage.removeItem(storageTokenKey);
    window.localStorage.removeItem(storageUserKey);
    delete axios.defaults.headers.common["Authorization"];
    resolve();
  });
