import { IRole } from "@iot-uta-devices/iot-uta-devices-interfaces";

// ToDo: Importare lo store e i ruoli utente da qui invece che passarli dal componente come parametro alle funzioni.

export const hasMachineViewPermission = (roles: IRole): boolean =>
  roles.machines_viewer;

export const hasUserViewPermission = (roles: IRole): boolean =>
  roles.users_viewer;

export const hasUserAdminPermission = (roles: IRole): boolean =>
  roles.users_admin;

export const hasMachineProgrammingPermission = (roles: IRole): boolean =>
  roles.machines_programmer;

export const hasMachineAdminPermission = (roles: IRole): boolean =>
  roles.machines_admin;
