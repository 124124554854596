



























import Vue from "vue";

export default Vue.extend({
  name: "Footer",
  data: () => ({
    footerVoices: [
      {
        text: "SGD Group s.r.l.",
        to: "https://sgd-group.com/",
      },
      { text: "0422 9573" },
      { text: "info@sgd-group.com" },
      { text: "P. IVA 04419270261" },
      {
        text: "Privacy Policy",
        to: "https://sgd-group.com/privacy-policy/",
      },
    ],
  }),
});
