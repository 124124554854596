import { NavigationGuardNext, RawLocation, Route } from "vue-router";
import Vue from "vue";
import store from "@/store";
import {
  hasMachineViewPermission,
  hasUserViewPermission,
} from "@/utils/UserPermissions";

const BASE_REDIRECT = { path: "/", replace: true };

export const checkMachineViewPermission = (
  to: Route,
  from: Route,
  next: NavigationGuardNext<Vue>
): void => {
  const userRoles = store.getters.userRoles;
  if (hasMachineViewPermission(userRoles)) {
    next();
  } else {
    next(BASE_REDIRECT);
  }
};

export const checkUsersViewPermission = (
  to: Route,
  from: Route,
  next: NavigationGuardNext<Vue>
): void => {
  const userRoles = store.getters.userRoles;
  if (hasUserViewPermission(userRoles)) {
    next();
  } else {
    next(BASE_REDIRECT);
  }
};

export const checkNoPermission = (
  to: Route,
  from: Route,
  next: NavigationGuardNext<Vue>
): void => {
  const userRoles = store.getters.userRoles;
  if (!Object.values(userRoles).includes(true)) {
    next();
  } else {
    next(BASE_REDIRECT);
  }
};

export const redirectFromRoot = (): RawLocation => {
  if (store.getters.isLoggedIn) {
    const userRoles = store.getters.userRoles;
    if (hasMachineViewPermission(userRoles)) return "/machines";
    if (hasUserViewPermission(userRoles)) return "/users";
    return "/blank";
  }
  return "/login";
};

export const checkAuthentication = (
  to: Route,
  from: Route,
  next: NavigationGuardNext<Vue>
): void => {
  if (to.name === "Login" && store.getters.isLoggedIn) {
    next(BASE_REDIRECT);
  } else if (to.name !== "Login" && !store.getters.isLoggedIn) {
    next(BASE_REDIRECT);
  } else {
    next();
  }
};
