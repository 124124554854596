// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

// CSS globale
import "@/assets/css/main.css";

// Applicazione
import Vue from "vue";
import App from "./App.vue";
import filters from "./filters";
import components from "./globalComponents";
import plugins from "./plugins";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";

Object.keys(plugins).forEach((plugin) => Vue.use(plugins[plugin]));
Object.keys(filters).forEach((filter) => Vue.filter(filter, filters[filter]));
Object.keys(components).forEach((component) =>
  Vue.component(component, components[component])
);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
